.light-header-top-bar {
  height:70px;
  width:100%;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ECEEF3;
}

@media (max-width:800px) {
  .light-header-top-bar-container {
      display:block;
  }
}

.light-header-top-bar-container {
  width:1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;
}

.light-header-logo {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  letter-spacing: 0;
  display:flex;
  transform:scale(0.8);
}

@media (max-width:800px) {
  .light-header-logo {
    justify-content: center;
  }

}

.light-header-logo-container {
  display:flex;
  align-items: center;

}

.light-header-logo-text-container {
  display:flex;
  align-items: center;
  margin-left:9px;
  margin-bottom:3px;

}


.light-header-links-container {


}

.light-header-links-container a {
  font-family: Larsseit;
  cursor:pointer;
  color:#838383;
  text-decoration: none;
  font-size:14px;
  margin-left:57px;

}

.light-header-links-container a:hover {
  color:#000000;
}
