.projects-preview-section {
  padding-top: 80px;

}

.projects-preview-section-container {
  display:flex;
  position:fixed;


}

.project-section-container {
  min-height:100VH;

  border-right: 1px solid #EBEDF2;
  height: 100vh;
  overflow: auto;

}

.project-container {
    justify-content: center;
    width:50vw;
    padding-bottom:400px;

}

.project-section {
  padding-top:27px;
  width:100%;

}

.project-section-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom:10px;
  line-height: 24px;

}

.project-section-body {
  display:flex;
  font-size: 14px;
  line-height: 30px;

  justify-content: center;
  width:100%;
}

.project-section-body-container {
  border-bottom: 1px solid #EBEDF2;
  width:80%;
  padding-top:23px;
  padding-bottom:23px;
  display:flex;
  justify-content: center;
}

.project-section-body-text-container {
  width:100%;
}

.project-section-body-textarea {
  font-size: 14px;
  color: #000;
  font-family:Larsseit;
  width:100%;
  border: none;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
