.generic-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.7);
  z-index: 5000;
  width:100%;
  top:0;
  left:0;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.generic-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.generic-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;
}

.generic-modal-responsive-container{
  width:100%;


}

.generic-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.generic-modal-x-container {
  padding-right:20px;
  padding-top:15px;

}

.generic-modal-x {
  opacity:0.5;

}

.generic-modal-x:hover {
  opacity:1;

}

.generic-modal-title-body {
  margin-top:17px;
  width:100%;

}

.generic-modal-title-container {
  display:flex;
  margin-left:100px;
  margin-right:100px;

}

.generic-modal-title {

}

.generic-modal-title-label {
  font-size: 26px;
  font-weight: 500;
  text-align:left;
  width:100%;
}



.generic-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;
  max-height: 50vh;
}


.generic-modal-section {
  width:100%;
}

.generic-modal-section-container {
  height:100%;
  padding-left:100px;
  padding-right:100px;

  padding-bottom:30px;
  color:#535353 ;
  font-size:15px;
  line-height: 23px;


}


.generic-modal-section-row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:16px;

}

.generic-modal-section-column-padding-right {
  width:100%;
  margin-right: 17px;

}

.generic-modal-section-column{
  width:100%;


}


@media (max-width: 600px) {
  .generic-modal-section-row{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .generic-modal-section-column {
    padding-top:16px;


  }

  .generic-modal-section-row{
    padding-top:0px;

  }

  .generic-modal-section-container {
    height:100%;
    padding-left:50px;
    padding-right:50px;

  }

  .generic-modal-title-container {
    display:flex;
    margin-left:50px;
    margin-right:50px;

  }

}

.generic-modal-half-field-container {
  display:flex;
  width:100%;

}

.generic-modal-field-container {
  display:flex;
  width:100%;

}

.generic-modal-half-field {
  height:65px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #000;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Larsseit;
  outline: none;

}



.generic-modal-field {
  height:65px;
  width:436px;
  padding:auto 31 auto 31;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #000;
  background-color: #FAFBFE;
  border: solid 1px #EBEDF2;
  border-radius:5px;
  font-family: Larsseit;
  outline: none;

}


.generic-accept-message {
  font-size:12px;
  text-align: center;
  color:#ABABAB;
  margin-top:3px;

}

.generic-modal-error-message {
  font-size:13px;
  text-align: center;
  color:#EF7879;
  margin-top:3px;
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

}

.generic-modal-login-option {
  text-align: center;
  font-size: 13px;
  color:#ABABAB;
  display:flex;
  justify-content: center;
  margin-left:50px;
  margin-right:50px;
  margin-top:10px;
  margin-bottom: 10px;



}

.blue-log-in {
  color:#348EFF;
  opacity:1;

}

.blue-log-in:hover {
  opacity:0.6;
}

.generic-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.generic-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: center;
  font-size: 16px;
}


.generic-modal-primary-button {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
}

.generic-modal-primary-button-waiting {
  display:inline-block;
  height:52px;
  background: #348EFF;
  border-radius: 9px;
  width:169px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:0.6;
}

.generic-modal-primary-button:hover {
  opacity:0.6;


}

.generic-modal-primary-button-label {
  margin-top:13px;

}

.generic-modal-secondary-button {
  height:52px;
  width:169px;
  text-align: center;
  display:inline-block;
  background-color: none;
  color:#5C5C5C;
  border-radius: 8px;
  box-shadow:0px 0px 0px 1px #DCDCDC inset;
  opacity:1;
  margin-right:10px;
}

.generic-modal-secondary-button:hover {
  opacity:0.6;


}

.generic-modal-secondary-button-label {
  margin-top:13px;

}

.generic-modal-underline-blue{
  color:#348EFF;
  text-decoration:underline;

}

.generic-modal-text-bold{
  font-weight: 500;
  color:#000;

}
