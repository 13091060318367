.refer-friend {
  padding:30px 0px 40px 0px;
  width:100%;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #EBEDF2;
  background:#FFF;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  margin-top: 32px;
}


.refer-friend-container {
  width:800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
}



.refer-friend-text-container{
  width:50%;
}

.refer-friend-button-container{
  width:50%;
  display:flex;
  justify-content:flex-end;
}

@media (max-width:800px) {
  .refer-friend-container {
      display:block;
  }

  .refer-friend-text-container{
    width:100%;
  }

  .refer-friend-button-container{
    width:100%;
    display:flex;
    justify-content:flex-start;
    margin-top: 20px;
  }
}

.refer-friend-title {
  font-size:19px;
  font-weight: 500;
  color:#000;
  margin-bottom:10px;
}

.refer-friend-text{
  font-size:14px;
  color:#000;
  max-width:97%;
}


.blue-text {
  color:#226CFF;
}
