body {
  margin: 0;
  padding: 0;
  font-family: 'Larsseit',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
font-family: 'Larsseit';
src: url('./fonts/Larsseit.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}
@font-face {
font-family: 'Larsseit';
src: url('./fonts/Larsseit-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
 }

 @font-face {
 font-family: 'Larsseit';
 src: url('./fonts/Larsseit-Bold.ttf') format('truetype');
 font-weight: bold;
 font-style: normal
  }

  .loading-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0.5);
  z-index: 7000;
  width:100%;
  top:0;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}


.searching-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(255,255,255,1);
  z-index: 103;
  width:100%;
  top:0;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.searching-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  height:100VH;

}

.searching-container-vertical-center {
    padding-bottom:100px;

}

.searching-message-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
}

.searching-message {
  font-size:30px;
  font-weight: 500;

}
