.skill-block {
  margin-right:14px;
  margin-left:14px;

}

.skill-block-name {
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;

}


.skill-block-display-score-bar-container {
  display:flex;
  align-items: center;
  padding-top:1.5px;
  padding-bottom:1.5px;

}

.skill-block-display-score-bar-background {
  height: 9px;
  width:142px;
  background: #EBEDF2;
  border-radius: 4.5px;
  overflow:hidden;
  display:inline-block;
  margin-right: 9px;

}

@media (max-width:800px) {
  .skill-block-display-score-bar-background {
    width:122px;
  }

}

.skill-block-display-score-bar-percentile {
  background: #226CFF;
  border-radius: 4.5px 0 0 4.5px;
  height:100%;

}

.skill-block-display-score-label {
  display:inline-block;
  font-size:12px;

}

.skill-block-display-label {
  display:inline-block;
  font-size:14px;
}
