.filter-block {
  border-bottom: 1px solid #EBEDF2;
  padding:16px 24px;
}

.filter-block-top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.filter-block-title {
  font-size: 14px;
  color: #000000;
}

.filter-block-plus-container {
  height:100%;
}

.filter-block-plus {
  opacity: 0.5;

}

.filter-block-plus:hover {
  opacity: 1;
}

.filter-block-minus{
  opacity: 0.5;
  cursor: pointer;
  margin-bottom:5px;
}

.filter-block-minus:hover {
  opacity: 1;
}

.filter-block-expanded{
  margin-top:6px;

}

.filter-block-option {
    font-size:14px;
    color:#226CFF;
    cursor: pointer;
    max-width: -webkit-max-content;
}

.filter-block-option:hover {
    font-size:14px;
    color:#226CFF;
    text-decoration: underline;
}

.filter-block-pill {
  padding: 6px 13px;
  border-radius:100px;
  background:#226CFF;
  color:white;
  font-size: 14px;
  display:flex;
  margin-top:6px;
  max-width: -webkit-max-content;
  align-items: center;
}

/* .filter-block-pill-flex {
  padding: 6px 13px;
  border-radius:100px;
  background:#226CFF;
  color:white;
  font-size: 12px;
  display:flex;
  margin-left:10px;
  max-width: -webkit-max-content;
  align-items: center;
} */

.filter-block-pill-flex {
  padding: 2px 8px;
  border-radius:100px;
  /* background:#226CFF; */
  color:#226CFF;
  font-size: 11px;
  display:flex;
  margin-top:1px;
  margin-left:8px;
  text-transform: uppercase;
  max-width: -webkit-max-content;
  align-items: center;
  border:1px solid;
}

.filter-pill-x {
  margin-left:6px;
}

.filter-pill-x:hover {
  opacity:0.8;
}
