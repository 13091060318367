.homepage {

}


.homepage-hero-section {
  /* height:619px; */
  width:100%;
  background-color: #0C1B2B;
  background-image:linear-gradient(to right,rgba(12, 27, 43, 0.95),rgba(12, 27, 43, 0.7)), url('https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=c0882af6fc30960d027b31250a10b75d&auto=format&fit=crop&w=2851&q=80&rect=0,450,4800,10000');
  background-size: cover;
}


.homepage-hero-top-bar {
  height:80px;
  width:100%;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
}


.homepage-hero-top-bar-container {
  width:1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;
}

/* @media (max-width:800px) {
  .homepage-hero-top-bar-container {
      display:block;
  }
} */


.homepage-logo {
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;
  letter-spacing: 0;
  display:flex;
  cursor: pointer;
}

/* @media (max-width:800px) {
  .homepage-logo {
    justify-content: center;
    margin:15px 0;

  }

} */

.homepage-logo-container {
  display:flex;
  align-items: center;


}

.homepage-logo-text-container {
  display:flex;
  align-items: center;
  margin-left:9px;
  margin-bottom:3px;

}



.homepage-links-container a {
  font-family: Larsseit;
  cursor:pointer;
  color:#B2B7BB;
  text-decoration: none;
  font-size:14px;
  margin-left:57px;

}

/* @media (max-width:800px) {

  .homepage-links-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }


  .homepage-links-container a {
    font-family: Larsseit;
    cursor:pointer;
    color:#B2B7BB;
    text-decoration: none;
    font-size:14px;
    margin-left:10px;
    margin-right:10px;

  }


} */

.hide-on-mobile {
  visibility: visible;
  display: inline;
}

@media (max-width:800px) {
  .hide-on-mobile {
    visibility: hidden;
    display: none;
  }

}

.homepage-links-container a:hover {
  color:#FFF;
}


.homepage-hero-main-section {
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:65px;
  padding-bottom:130px;
}

.homepage-hero-main-section-container{
  width:1120px;
  margin-left:25px;
  margin-right:25px;
}

.homepage-center-container{
  /* display: flex;
  justify-content: center;
  text-align: center; */
}

@media (max-width:528px) {
  .homepage-hero-main-section-container{
    text-align: center;
  }
  .homepage-hero-headline {
    font-size: 42px;
    line-height: 58px;
  }

}


.homepage-hero-headline {
  font-weight: 500;
  font-size: 52px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 68px;
  max-width:610px;
  /* text-align: center; */
}

@media (max-width:700px) {
  .homepage-hero-headline {
    font-size: 43px;
    line-height: 58px;
  }
}

.homepage-hero-body-text {
  margin-top:19px;
  margin-bottom:45px;
  font-size: 20px;
  color: #B2B7BB;
  letter-spacing: 0;
  line-height: 34px;
  max-width:548px;
}

.large-button {
  background: #226CFF;
  font-weight: 500;
  font-size: 17px;
  color: #FFFFFF;
  letter-spacing: 0;
  padding:15px 40px;
  outline:none;
  border:none;
  border-radius: 12px;
  font-family: Larsseit;
  cursor:pointer;
}

.large-button:hover {
  background-color:#1D5AD4;

}

.homepage-companies-section {
  width:100%;
  /* display:flex; */

}

.homepage-companies-section-title{
  font-weight:500;
  font-size: 16px;
  color: #226CFF;
  letter-spacing: 1.2px;
  text-align: center;
  margin-top:52px;
  margin-left:15px;
  margin-right:15px;
}

.homepage-companies-section-logos-container {
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:34px;
}

.homepage-companies-section-logo-container{
  width:1120px;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom:30px;

}

@media (max-width:800px) {
  .homepage-companies-section-logo-container{
    display:block;

  }

}


.homepage-companies-section-logos-container img {
  max-height:45px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
}

.homepage-company-logo {
  display:flex;
  justify-content: center;

}

.homepage-jobs-bar {
  background:#FAFBFE;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.homepage-jobs-bar-container {
  width:1120px;
  display: flex;
  justify-content: space-between;
  padding:25px 0 25px 0;
  margin-left:25px;
  margin-right:25px;
}




.homepage-jobs-bar-role{
  font-size: 18px;
  color: #838383;

}

@media (max-width:800px) {

  .homepage-jobs-bar-container {
    display:block;
    text-align: center;
  }

  .homepage-jobs-bar-role{
    margin-top:5px;
    margin-bottom:5px;
  }
}

.homepage-jobs-bar-role-link{
  font-size: 18px;
  color: #226CFF;
  /* text-decoration: underline; */
  cursor: pointer;

}

.homepage-jobs-bar-role-link:hover{
  font-size: 18px;
  color: #226CFF;
  text-decoration: underline;

}

.homepage-how-it-works-section{
  margin-top:87px;
  margin-left: 25px;
  margin-right:25px;
}

@media (max-width:800px) {
  .homepage-how-it-works-section{
    margin-top:50px;
  }

}

.homepage-how-it-works-title {
  font-weight:500;
  font-size: 40px;
  color: #000000;
  text-align: center;
}

.homepage-how-it-works-timeline {
  width:100%;
  display:flex;
  justify-content: center;
  margin-top:50px;
  margin-bottom:50px;

}

.homepage-how-it-works-timeline-mobile {
  width:100%;
  display:none;
  justify-content: center;
  margin-top:50px;
  margin-bottom:50px;
}

@media (max-width:800px) {
  .homepage-how-it-works-timeline {
    display:none;
  }

  .homepage-how-it-works-timeline-mobile {
    display:flex;
  }
}

.homepage-how-it-works-timeline-container {
  width:850px;
}

.homepage-how-it-works-step {
  width:100%;
  display: flex;
  min-height:175px;
  justify-content: space-between;

}



.homepage-how-it-works-timeline-step{
    width:100%;
    height:100%;
    display:flex;
}

.homepage-how-it-works-timeline-step-column-1 {
  width:40%;
  height:100%;

}

@media (max-width:800px) {

  .homepage-how-it-works-timeline-title {
    font-weight:500;
    font-size: 22px;
    color: #000000;
    margin-bottom:12px;
    text-align: center;
    margin-top:12px;
  }

  .homepage-how-it-works-timeline-body {
    font-size: 16px;
    color: #838383;
    line-height: 26px;
    text-align: center;
  }

}


.homepage-how-it-works-timeline-image-left {
  display: flex;
  justify-content: flex-end;

}

.homepage-how-it-works-timeline-image-left-2 {
  display: flex;
  justify-content: flex-end;

}

.homepage-how-it-works-timeline-image-left img {
  max-width:189px;
  max-height: 120px;

  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;

}

.homepage-how-it-works-timeline-image-left-2 img {
  max-width:247px;
  max-height: 140px;

  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;

}

.homepage-how-it-works-timeline-image-right img {
  max-width:245px;
  max-height: 122px;

  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;

}

.homepage-how-it-works-timeline-image-right-2 img {
  max-width:236px;
  max-height: 150px;

  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;

}




.homepage-how-it-works-timeline-step-column-2 {
  width:180px;
  height:100%;
}



.homepage-how-it-works-timeline-step-column-3{
  width:40%;
  height:100%;

}


.homepage-how-it-works-timeline-step-number{
  font-weight:500;
  font-size: 16px;
  color: #226CFF;
  letter-spacing: 1.2px;
  text-align: center;
}

.homepage-how-it-works-timeline-vertical-line{
  background:#EBEDF2;
  width:1px;
  min-height:175px;
  height:100%;
  margin:10px auto 10px auto;

}

.homepage-how-it-works-timeline-vertical-line-mobile{
  background:#EBEDF2;
  width:1px;
  height:35px;
  margin:10px auto 10px auto;

}
.homepage-how-it-works-timeline-title {
  font-weight:500;
  font-size: 22px;
  color: #000000;
  margin-bottom:12px;
}

.homepage-how-it-works-timeline-body {
  font-size: 16px;
  color: #838383;
  line-height: 26px;
}

.homepage-how-it-works-timeline-text-left {
  text-align: right;
  display: flex;
  justify-content: flex-end;

}

.homepage-benefits-section{
  width:100%;
  border-top: 1px solid #EBEDF2;
  padding-top:100px;
  padding-bottom:100px;
  display:flex;
  justify-content: center;

}

@media (max-width:800px) {
  .homepage-benefits-section{
    padding-top:50px;
    padding-bottom:50px;
  }


}

.homepage-benefits-section-container {
  width:1120px;
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  margin-right:25px;

}


.homepage-benefits-section-column-left{
  width:50%;
}

.homepage-benefits-section-column-right{
  width:50%;
}

.homepage-benefits-section-column-right-mobile{
  display:none;
  width:100%;
}

.homepage-benefits-section-column-right-text{
  width:50%;
  padding-left:50px;
}

@media (max-width:900px) {
  .homepage-benefits-section-container {
    display:block;
    text-align:center;
  }

  .homepage-benefits-section-column-left{
    width:100%;
  }

  .homepage-benefits-section-column-right{
    width:100%;
  }

  .homepage-benefits-section-column-right-text{
    width:100%;
    padding-left:0px;
  }

  .homepage-benefits-section-column-right-mobile{
    display:flex;
    width:100%;
  }
}


.homepage-benefits-section-blue-benefit{
  font-weight: 500;
  font-size: 16px;
  color: #0068FF;
  letter-spacing: 1.2px;

}

.homepage-benefits-section-title{
  font-weight:500;
  font-size: 40px;
  color: #000000;
  margin-top:20px;
  margin-bottom:24px;
  padding-right:30px;
}

.homepage-benefits-section-body {
  font-size: 18px;
  color: #838383;
  line-height: 29px;
  padding-right:30px;
}

.homepage-benefits-section-title-right{
  font-weight:500;
  font-size: 40px;
  color: #000000;
  margin-top:20px;
  margin-bottom:24px;
}

.homepage-benefits-section-body-right {
  font-size: 18px;
  color: #838383;
  line-height: 29px;
}

.homepage-benefits-image-right {
  padding-left: 50px;
  height:100%;
  display: flex;
  align-items: center;
  max-width:484px;


}


.homepage-benefits-image-right img {
  /* max-height: 324px; */
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  display:flex;
  width:100%;

}

.homepage-benefits-image-left {
  padding-right: 50px;
  height:100%;
  display: flex;
  align-items: center;
  max-width:484px;
}

.homepage-benefits-image-left img {
  width:100%;
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
}

@media (max-width:900px) {
  .homepage-benefits-image-left {
    padding-right: 0;
    display:none;
  }

  .homepage-benefits-image-right {
    margin-top:35px;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .homepage-benefits-section-title{
    padding-right:0;
    font-size: 30px;
  }

  .homepage-benefits-section-body {
    padding-right:0;
  }

  .homepage-benefits-section-title-right{
    padding-left:0;
    font-size: 30px;
  }

  .homepage-benefits-section-body-right {
    padding-right:0;
  }


}

.homepage-bottom-cta {
  background:#FAFBFE;
  padding:50px 0 50px 0;
  width:100%;
  border-top: 1px solid #EBEDF2;
}

.homepage-bottom-cta-container{
  /* width:100%; */
  margin:0 25px 0 25px;
  text-align: center;
}

.homepage-bottom-cta-title {
  font-weight:500;
  font-size: 30px;
  color: #000000;
  text-align: center;
}

.homepage-bottom-cta-body{
  font-size: 18px;
  color: #838383;
  letter-spacing: 0;
  text-align: center;
  line-height: 29px;
  max-width: 526px;
  margin: 20px auto 26px auto;
}

.homepage-footer {
  background:#0C1B2B;
  min-height:360px;
  width:100%;
  display: flex;
  justify-content: center;
}

.homepage-footer-main {
  width:1120px;
  margin-left: 25px;
  margin-right:25px;

}


.homepage-footer-container {
  display: flex;
  justify-content:space-between;
  width:100%;
  margin-top:71px;

}



.homepage-footer-column {
  width:25%;
  margin-bottom:15px;

}



.homepage-footer-column-header {
  color:#B2B7BB;
  font-size:18px;
  margin-bottom:29px;
}

@media (max-width:800px){
  .homepage-footer-container {
    display:block;
    text-align:center;
  }

  .homepage-footer-column {
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    width:100%;
  }
  .homepage-footer-column-header {
    color:#B2B7BB;
    font-size:18px;
    margin-bottom:10px;
    margin-top:29px;
  }

}



.homepage-footer-column a {
  color:#FFFFFF;
  font-size:16px;
  text-decoration: none;

}

.homepage-footer-link {
  margin-bottom:16px;

}

.homepage-footer-column a:hover {
  color:#FFF;
  cursor: pointer;

}

.homepage-footer-column-half {
  width:50%;
  margin-bottom:15px;
  color:#34404D;
  font-size:14px;

}

.homepage-footer-column-half-2 {
  width:50%;
  margin-bottom:15px;
  display:flex;
  justify-content: flex-end;
  color:#34404D;
  font-size:14px;

}

.homepage-footer-column-half-2 a{
  color:#34404D;
  text-decoration: none;

}

.homepage-footer-column-half-2 a:hover{
  color:#FFFFFF;
}

.add-space{
  margin-left:40px;
}

.homepage-footer-legal-links {
  display: flex;
  justify-content: space-between;

}

@media (max-width:800px) {
  .homepage-footer-column-half {
    width:100%;
  }
  .homepage-footer-column-half-2 {
    width:100%;
    justify-content: center;
  }



}
