
.review-menu{
  overflow: hidden;
  width:100%;
  bottom:0;
}


.review-menu-container {
  width:100%;
  height:224px;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  bottom:0;
  position:fixed;
  z-index: 102;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  box-shadow: 0 -9px 25px 0 rgba(0,0,0,0.05);
}



.review-menu-top-tab {
  height:48px;
  width:100%;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align:center;
}

.review-menu-top-tab-label-container {
  opacity:0.5;

}

.review-menu-top-tab-label-container:hover {
  opacity:1;

}

.review-menu-top-tab-arrow {
  display:flex;
  justify-content: center;
  margin-bottom:1px;

}

.review-menu-top-tab-label {
  color: #000;
  font-size:14px;

}

.review-menu-body-container {
  margin-top:3px;

}

.review-menu-back-to-dashboard {
  display:absolute;
  margin-left:45px;
  margin-bottom: -34px;
  margin-top: 18px;
  color:#000;
  font-size:14px;
  opacity:0.5;
}

.review-menu-back-to-dashboard:hover {
  opacity:1;

}

.review-menu-back-to-dashboard-label{
  margin-left:5px;
  display:inline-block;
  cursor: pointer;
}

.review-menu-top-info {
  display: flex;
  justify-content: space-between;
}

.review-menu-hiring-bonus {
  display: absolute;
  align-self: flex-end;
  margin-right:45px;
  margin-bottom: -34px;
  margin-top: 18px;
  color:#000;
  font-size:14px;
  opacity:1;
}

.review-menu-hiring-bonus:hover {
  opacity:1;
}

.review-menu-hiring-bonus-label{
  margin-right:5px;
  display:inline-block;
  cursor: pointer;
}

.review-menu-submit-review-block-container {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.review-menu-submit-review-block {
  width:660px;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 34px 0 rgba(189,189,206,0.21);
  border-radius: 8px;
}

.review-menu-submit-review-block-label-container {
  padding:15px 0;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;
}

.review-menu-submit-review-block-label {
  font-size: 16px;
  font-weight: 500;
}

.review-menu-submit-review-block-body{
  padding-top:16px;
  padding-bottom:16px;
  border-bottom: 1px solid #EBEDF2;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;

}

.review-menu-submit-review-block-prompt {
  font-size: 14px;
  text-align:center;
  margin-left:10px;
  margin-right:10px;
}

.review-menu-submit-review-block-prompt-label {
  margin-left:10px;
  margin-right:10px;

}

.underline-bold {
  text-decoration: underline;
  font-weight: 500;

}

.review-menu-submit-review-block-persons {
  margin-top:13px;

}

.review-menu-submit-review-block-persons-container {
  width:173px;
  display:flex;
  justify-content: center;
  width:100%;

}

.person-block-a {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  padding:14px 0;
  width:173px;
  display:inline-block;
  margin-right:15px;
  text-align:center;
  vertical-align: center;
  cursor: pointer;

}

.person-block-b {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  padding:14px 0;
  width:173px;
  display:inline-block;
  text-align:center;
  cursor: pointer;

}

.person-block-label {
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
  opacity:0.3;
  font-size:14px;
  font-weight: 500;

}

.submit-button {
  height:57px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  color: #2D8BFF;
  opacity:1;
  font-weight: 500;
  cursor: pointer;
}

.submit-button:hover {
  opacity:0.6;

}

.submit-button-label {
  opacity:1;


}

.submit-button-label:hover {
  opacity:0.6;

}



.review-menu-progress-bar {
  display:flex;
  margin-top:19px;
  flex-direction: column;
}

.review-menu-progress-bar-label {
  display:inline-block;
  font-size:14px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.review-menu-progress-bar-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.review-menu-progress-bar-background {
  height: 9px;
  width:192px;
  background: #EBEDF2;
  border-radius: 4.5px;
  overflow:hidden;
  display:inline-block;
  margin-right: 9px;
  margin-bottom: 40px;

}

.review-menu-progress-bar-percentile {
  background: #226CFF;
  border-radius: 4.5px 0 0 4.5px;
  height:100%;

}

.searching-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(255,255,255,1);
  z-index: 103;
  width:100%;
  top:0;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.searching-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  height:100VH;

}

.searching-container-vertical-center {
    padding-bottom:100px;

}

.searching-message-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
}

.searching-message {
  font-size:30px;
  font-weight: 500;

}

.screen-size-overlay {
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,1);
  z-index: 103;
  width:100%;
  top:0;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  visibility:hidden;
  opacity:0;
}


@media only screen and (max-width: 900px) {
    .screen-size-overlay {
        visibility: visible;
        opacity:0.9;
    }
}

.screen-size-container {
  justify-content: center;
  display:flex;
  width:100%;
  align-items: center;
  height:100VH;

}

.screen-size-container-vertical-center {
    padding-bottom:100px;

}

.screen-size-message-container{

  justify-content: center;
  text-align:center;
  width:100%;
  align-items: center;
  margin-bottom:15px;

}

.screen-size-message {
  font-size:22px;
  font-weight: 500;
  color:white;


}

.screen-size-message-small {
  font-size:16px;
  font-weight: 400;
  color:#AFAFAF;
  margin-top:10px;

}

.static-underline {
  text-decoration: underline;
  font-style: italic;
}
