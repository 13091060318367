
.review-lander-top-bar {
  width:100%;
  padding-top:15px;
  padding-bottom:15px;
  border-bottom: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  position:fixed;
  z-index:1;
  background:#FFF;
  height:40px;
}

.review-lander-top-bar-container{
  margin-left:25px;
  margin-right:25px;

}


.review-lander-top-section{
  margin-left:25px;
  margin-right:25px;
  padding-top:123px;
}

@media (max-width:600px) {
  .review-lander-top-section{
    padding-top:114px;
  }

}


.review-lander-back-to-profile {
  margin-left:4vw;
  color:#000;
  font-size:14px;
  opacity:0.6;
}

.review-lander-back-to-profile:hover {
  opacity:1;

}


.review-lander-back-to-profile-label{
  margin-left:5px;
  display:inline-block;
  cursor: pointer;


}

@media (max-width: 600px) {

  .review-lander-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}

.review-lander-back-to-profile {
  margin-left:4vw;
  color:#000;
  font-size:14px;
  opacity:0.6;
  position:absolute;
  margin-top:10px;
}

.review-lander-back-to-profile:hover {
  opacity:1;

}


.review-lander-back-to-profile-label{
  margin-left:5px;
  display:inline-block;

}

.review-lander-back-to-profile-label-mobile{
  margin-left:5px;
  display:none;
}

@media (max-width: 600px) {


  .review-lander-back-to-profile-label-mobile{
    display:inline-block;

  }

  .review-lander-top-bar-container{
    margin-left:10px;
    margin-right:10px;
  }
}

.review-lander-hero-section{
  width:100%;
  border-top: 1px solid #EBEDF2;
  padding-top:130px;
  padding-bottom:10px;
  display:flex;
  justify-content: center;

}

@media (max-width:800px) {
  .review-lander-hero-section{
    padding-top:100px;
    padding-bottom:0px;
  }


}

.review-lander-hero-section-container {
  width:1120px;
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  margin-right:25px;

}


.review-lander-hero-section-column-left{
  width:50%;
}

.review-lander-hero-section-column-right{
  width:50%;
}

.review-lander-hero-section-column-right-mobile{
  display:none;
  width:100%;
}

.review-lander-hero-section-column-right-text{
  width:50%;
  padding-left:50px;
}

@media (max-width:900px) {
  .review-lander-hero-section-container {
    display:block;
    text-align:center;
  }

  .review-lander-hero-section-column-left{
    width:100%;
  }

  .review-lander-hero-section-column-right{
    width:100%;
  }

  .review-lander-hero-section-column-right-text{
    width:100%;
    padding-left:0px;
  }

  .review-lander-hero-section-column-right-mobile{
    display:flex;
    width:100%;
  }
}


.review-lander-hero-section-blue-benefit{
  font-weight: 500;
  font-size: 16px;
  color: #0068FF;
  letter-spacing: 1.2px;

}

.review-lander-hero-section-title{
  font-weight:500;
  font-size: 40px;
  color: #000000;
  margin-top:20px;
  margin-bottom:24px;
  padding-right:30px;
}

.review-lander-hero-section-body {
  font-size: 18px;
  color: #838383;
  line-height: 29px;
  padding-right:50px;
}

.review-lander-hero-section-title-right{
  font-weight:500;
  font-size: 40px;
  color: #000000;
  margin-top:20px;
  margin-bottom:24px;
}

.review-lander-hero-section-body-right {
  font-size: 18px;
  color: #838383;
  line-height: 29px;
}

.review-lander-hero-image-right {
  padding-left: 50px;
  height:100%;
  display: flex;
  align-items: center;
  max-width:484px;


}


.review-lander-hero-image-right img {
  /* max-height: 324px; */
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  display:flex;
  width:100%;

}

.review-lander-hero-image-left {
  padding-right: 50px;
  height:100%;
  display: flex;
  align-items: center;
  max-width:484px;
}

.review-lander-hero-image-left img {
  width:100%;
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
}

@media (max-width:900px) {
  .review-lander-hero-image-left {
    padding-right: 0;
    display:none;
  }

  .review-lander-hero-image-right {
    margin-top:35px;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .review-lander-hero-section-title{
    padding-right:0;
    font-size: 30px;
  }

  .review-lander-hero-section-body {
    padding-right:0;
  }

  .review-lander-hero-section-title-right{
    padding-left:0;
    font-size: 30px;
  }

  .review-lander-hero-section-body-right {
    padding-right:0;
  }


}

.review-lander-hero-table {
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  width: 100%;
  height:267px;
  background: #FFF;
  border: 1px solid #EBEDF2;
}

.review-lander-hero-table-row {
  padding:22px 38px;
  border-bottom: 1px solid #EBEDF2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-lander-hero-table-button-row {
  display: flex;
  padding:16px;
  justify-content: center;
  align-items: center;
}

.review-lander-hero-table-row-label {
  font-weight: 500;
  font-size: 19px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
}

.review-lander-hero-table-row-value {
  font-weight:500;
  font-size: 35px;
  color: #0068FF;
  letter-spacing: 0;
  text-align: center;
}

.review-lander-divider-container{
  display:flex;
  justify-content: center;
  width: 100%;
}

.review-lander-divider {
  height:1px;
  width:100%;
  background-color: #EBEDF2;
  margin-top:90px;
  margin-bottom:60px;

}


.review-lander-cta-button-container {
  display:flex;
  justify-content: center;
  margin-top:25px;
}

.review-lander-cta-button {
  width:256px;
  height:56px;
  background: #226CFF;
  border-radius: 7.2px;
  margin-top:46px;
  display:flex;
  align-items: center;
  justify-content: center;

}

.review-lander-cta-button:hover {
  background: #69ABFF;

}

.review-lander-cta-button-label {
  font-weight:400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;

}

.review-lander-faq-section{
  background: #FAFBFE;
  border: 1px solid #EBEDF2;
  padding-top:83px;
  padding-bottom:183px;
  margin-top:70px;

}

.review-lander-faq-section-container-margin {
  padding-right:15px;
  padding-left:15px;
}

.review-lander-faq-section-container {
  width:100%;
}

.review-lander-faq-section-title{
  font-weight: 500;
  font-size: 35px;
  color: #000;
  text-align: center;
  width:100%;
  margin-bottom:29px;

}

.review-lander-faq-section-cards {
  display:flex;
  width:100%;
  justify-content: center;
}
