.applicant-search {
  padding-bottom:100px;
  width:100%;
  background-color:#FAFBFF;

}

.background {
  background-color:#FAFBFF;
  height:120vh;
  width:100vw;
  position:fixed;
  z-index: -1;
}

.applicant-search-main {
  display:flex;
  justify-content: center;
  width:100%;
  margin-top:16px;
}

.applicant-search-main-container {
  width:1120px;
  margin-left: 15px;
  margin-right:15px;
  display: flex;
  height:100%;
  justify-content: space-between;
}


.applicant-search-filters-container {
  width:100%;
  max-width: 300px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
}

@media (max-width:700px){
  .applicant-search-main-container {
    display:block;
  }

  .applicant-search-filters-container {
    width:100%;
    max-width: 700px;
  }

}

.applicant-search-applicant-card-container {
  height:100%;
  width:100%;
}
