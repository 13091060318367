select {
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 1px solid #838383;
  height:41px;
  background:#FFFFFF;
  max-width:303px;
  width:100%;
  margin-bottom:19px;
  outline:none;
  border-radius:3px;
  padding:0 10px 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image:
   linear-gradient(45deg, transparent 50%, #ABABAB 50%),
   linear-gradient(135deg, #ABABAB 50%, transparent 50%);
 background-position:
   calc(100% - 20px) calc(1em + 4px),
   calc(100% - 15px) calc(1em + 4px),
   calc(100% - 2.5em) 0.5em;
 background-size:
   5px 5px,
   5px 5px,
   1px 1.5em;
 background-repeat: no-repeat;
}

select:focus {
  border: 1px solid #000000;
}

input {
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 1px solid #838383;
  height:41px;
  background:#FFFFFF;
  max-width:550px;
  width:100%;
  margin-bottom:16px;
  outline:none;
  border-radius:3px;
  padding:0 10px 0 10px;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}

.radio-section {
  display: flex;
  margin-bottom:30px;
  flex-wrap: wrap;

}

.radio-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-right: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.radio-section-sponsorship {

}

.radio-button-container {
  display: flex;
  width:300px;
}

.radio-button {

}

.radio-button-label {
  font-size: 14px;
}

.radio-text {
  align-self: flex-start;
  top: -3px;
  position: relative;
  font-size: 14px;
}

.radio-text-authorized {
  left: -19px;
}

.radio-text-not-required {
  left: -37px;
}

input:focus {
  border: 1px solid #000000;
}

.half-input {
  width: 288px;
}

.college-input {
  width: 210px;
}

.degree-input {
  width: 210px;
}
.year-input {
  width: 70px;
}

.quarter-input {
  width: 118px;
}

.date-input {
  width: 150px;
  margin-right: 20px;
}

.long-input {
  width: 450px;
}

.form-left-half {
  margin-right: 20px;
}

.quarter-select {
  width:25%;
}

.form-title {
  font-weight: 500;
  font-size: 19px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom:10px;
  margin-top: 39px;
}

.form-block-2 {
  display: flex;
}

@media (max-width:800px) {
  .form-block-2 {
    display: flex;
    flex-wrap: wrap;

  }

}

.form-section {
  padding-bottom: 30px;
  border-bottom: 1px solid #EBEDF2;
}

.form-section-single {
}


.form-label {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size:14px;
  line-height: 24px;
}

.form-label-secondary {
  font-size: 14px;
  margin-bottom: 15px;
  color: #838383;
  /* line-height: 24px; */
  max-width:394px;
  margin-top:-15px;
}

.form-label-secondary-space {
  font-size: 14px;
  margin-bottom: 15px;
  color: #838383;
  /* line-height: 24px; */
  max-width:464px;
  margin-top:15px;
}

.form-divider {
  width:100%;
  height: 1px;
  background:#EBEDF2;
  margin:30px 0;

}

.container {
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  font-family: Larsseit;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 1  ;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border:1px solid #838383;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #EBEDF2;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #226CFF;
  border:1px solid #226CFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height:7px;
  border: solid white;
  border-width: 0 1.5px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
