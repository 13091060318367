.filter-block-talent {
  border-bottom: 1px solid #EBEDF2;
  padding:16px 24px;
}

.filter-block-talent-top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.filter-block-talent-title {
  font-size: 14px;
  color: #000000;
}

.filter-block-talent-plus-container {
  height:100%;
}

.filter-block-talent-plus {
  opacity: 0.5;
  cursor: pointer;
}

.filter-block-talent-plus:hover {
  opacity: 1;
}

.filter-block-talent-minus{
  opacity: 0.5;
  cursor: pointer;
  margin-bottom:5px;
}

.filter-block-talent-minus:hover {
  opacity: 1;
}

.filter-block-talent-expanded{
  margin-top:6px;

}

.filter-block-talent-option {
    font-size:14px;
    color:#226CFF;
    cursor: pointer;
}

.filter-block-talent-option:hover {
    font-size:14px;
    color:#226CFF;
    text-decoration: underline;
}

.filter-block-talent-pill {
  padding: 6px 13px;
  border-radius:100px;
  background:#226CFF;
  color:white;
  font-size: 14px;
  display:flex;
  margin-top:6px;
  max-width: -webkit-max-content;
  align-items: center;
}

.filter-pill-x {
  margin-left:6px;
}

.filter-pill-x:hover {
  opacity:0.8;
}

.filter-block-talent-input-label {
  color:#838383;
  font-size:14px;
  margin-top:6px;
}

.filter-block-talent-input {
  border:1px solid #EBEDF2;
  width:100px;
  margin-top:6px;
}

.filter-block-talent-input:focus {
  border:1px solid #EBEDF2;
}

.filter-tooltip-underline {
  color:#226CFF;
  /* cursor: pointer; */
}

.tooltip {
  max-width: 300px;
}

.__react_component_tooltip {
  opacity:1 !important;
  border-radius: 7px !important;
  margin-left:12px !important;
  font-size:14px !important;
}
