

.all-options-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: 'red';
  margin-bottom: 30px;
}

.option-box-container {
  background: #EBEDF2;
  color: #838383;
  content: center;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 19px;
  padding: 18px;
  margin: 2px;
  cursor: pointer;
}

.option-box-container-selected {
  background: #838383;
  color: rgb(255, 255, 255);
  content: center;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 19px;
  padding: 18px;
  margin: 2px;
  cursor: pointer;
}

.option-box-container-selected:hover {
  background: #9a9a9a;
  color: rgb(255, 255, 255);

}

.option-box-container:hover {
  background: #dadde4;
  color: #838383;

}

.option-box-label {
  text-align: center;
  align-self: center;
  font-size: 14px;
}
