.email-sidebar {
  width:533px;
  height:100%;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEDF2;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  right:0;
  top:0;
  position:fixed;
  z-index: 9999;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-left: 1px solid #EBEDF2;
  margin-right:-534px;
}

@media (max-width:600px) {
  .email-sidebar {
    width:100%;
    height:100%;
  }

}

.email-sidebar-container {
  padding:44px 38px;
}

.email-sidebar-x-container {
  justify-content: flex-end;
  display: flex;
}

.email-sidebar-x {
  opacity:0.5;
}

.email-sidebar-x:hover {
  opacity:1;
}

.email-sidebar-title {
  font-weight:500;
  font-size: 19px;
  color: #000000;

}

.small-button {
  font-size:14px;
  padding:13px 25px;
  border-radius: 7px;
}

.small-button-waiting {
  font-size:14px;
  padding:8px 25px;
  border-radius: 7px;
  opacity:0.6;
  min-width:160px;
}

.email-sidebar-button-container {
  margin-top:15px;
}

/* .email-sidebar-form-label {
  margin-top:10px;
  font-size:14px;

} */

.email-sidebar-container input {
  margin-bottom:0

}

.email-sidebar-container .salary-block {
  margin-bottom:0

}

.email-sidebar-container .form-label {
  margin-bottom: 5px;

}
