
textarea::-webkit-input-placeholder {
color: #838383;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: #838383;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: #838383;
}

textarea:-ms-input-placeholder {
color: #838383;
}
