.create-account-step {
  background-color:#FAFBFF;
  min-height:100vh;
  width:100%;
}

.disabled {
  opacity: 0.3;
}

.create-account-top-bar {
  height:70px;
  width:100%;
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ECEEF3;
}

@media (max-width:800px) {
  .create-account-top-bar-container {
      display:block;
  }
}

.create-account-container {
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;

}

.create-account-top-bar-container {
  width:1120px;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;
}

.create-account-logo {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  display:flex;
  transform:scale(0.8);
}

.word-count-bar {
  display: flex;
  justify-content: space-between;
}

@media (max-width:800px) {
  .create-account-logo {
    justify-content: center;
  }
}

.create-account-logo-container {
  display:flex;
  align-items: center;

}

.create-account-logo-text-container {
  display:flex;
  align-items: center;
  margin-left:9px;
  margin-bottom:3px;

}

.create-account-links-container {


}

.create-account-links-container a {
  font-family: Larsseit;
  cursor:pointer;
  color:#838383;
  text-decoration: none;
  font-size:14px;
  margin-left:57px;

}

.create-account-links-container a:hover {
  color:#000000;
}

.create-account-step-number-container {
  display: flex;
  justify-content: center;
  width:100%;
}
.create-account-step-number {
  text-align: center;
  margin:25px;
  font-size: 14px;
  color: #838383;
}

.create-account-form-container {
  display: flex;
  justify-content: center;
  width:1120px;
}

.create-account-form-body {
  width:500px;
  /* margin-left: 25px;
  margin-right:25px; */
  margin-bottom:75px;
  margin-top:30px;
  display: flex;
}

.create-account-form-body-container{
  width:100%;
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  border-radius:3px;
  display:flex;

}

.create-account-form-body-container-inner {
  padding-top:50px;
  width:100%;
}

@media (max-width:800px) {
  .create-account-form-body-container-inner {
    padding:65px 40px 0px 40px;
    width:100%;
  }

}

.text-area {
  color: #000;
  font-family: Larsseit;
  letter-spacing: 0;
  width: 97%;
  font-size: 14px;
  resize: none;
  min-height:31px;
  border: solid 1px #838383;
  border-width: 1px;
  letter-spacing: 0;
  border-radius: 3px;
  padding: 10px;
  overflow: auto;
  outline: none;
  background-color: transparent;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 16px;
}

.text-area:focus {
  border: solid 1px #000;

}

.create-account-form-header{
    border-bottom: 1px solid #EBEDF2;
    width:100%;
    padding-bottom:36px;
    margin-bottom:36px;
}

.create-account-form-header-title {
  font-weight:500;
  font-size: 22px;
  color: #000000;
}

.create-account-form-header-subtitle {
  font-weight: 500;
  font-size: 16px;
  color: #0068FF;
  letter-spacing: 1.2px;
  margin-top:30px;
  margin-bottom:20px;
}

.create-account-form-header-text {
  font-size: 14px;
  color: #838383;
  line-height: 24px;
  max-width:560px;
}

.create-account-form-buttons-area {
  border-top: 1px solid #EBEDF2;
  padding-top:27px;
  padding-bottom:27px;
  margin-top:30px;
}

.create-step-buttons-container {
  display: flex;
  justify-content: center;
}

.go-back-button {
  margin-right: 40px;
  padding-top: 15px;
  color: #838383;
  font-size:14px;
  cursor: pointer;
}

.go-back-button:hover {
  margin-right: 40px;
  padding-top: 15px;
  color: #656565;
}

.add-another-button {
  margin-right: 40px;
  margin-bottom: 40px;
  padding-top: 15px;
  color: #226CFF;
  font-size:14px;
  cursor: pointer;
}

.add-another-button:hover {
  margin-right: 40px;
  padding-top: 15px;
  color: #1f5dd7;
}

.trash-icon {
  left: -10px;
  position: relative;
  cursor: po
}
.trash-icon-container {
  left: -10px;
  position: relative;
}
.trash-icon-container:hover {
  opacity: 0.7;
}

.trash-icon-container-college {
  left: -10px;
  position: relative;
}
.trash-icon-container-college:hover {
  opacity: 0.7;
}

.work-section {
  margin: 15px 0;
}

.college-section {
  margin: 15px 0;
}

.edit-profile-modal-profile-photo {
  display:flex;
  justify-content: flex-start;
  width:100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .edit-profile-modal-profile-photo {
    display:block;
    margin-top:15px;
  }

}

.edit-profile-modal-profile-picture {
  background: #D8D8D8;
  height: 111px;
  width:111px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.edit-profile-modal-profile-photo-exists{
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;
}

.edit-profile-modal-profile-photo-exists:hover{
  opacity:0.6;

}

.edit-profile-modal-profile-picture img {
  height:100%;
  position:absolute;
  width:100%;
  object-fit: cover;
}

.edit-profile-modal-profile-photo-overlay{
  position:absolute;
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.63);
  display:flex;
  justify-content: center;
  align-items: center;

}



.edit-profile-modal-profile-photo-overlay-message{
  color:#FFFFFF;
  text-align: center;
  font-size:12px;
  line-height: 15px;
  width:61px;

}

.edit-profile-modal-profile-photo-doesnt-exists {
  height: 100%;
  width:100%;
  position:relative;
  opacity:1;


}

.edit-profile-modal-profile-photo-doesnt-exists:hover {
  opacity:0.6;
}

.edit-profile-modal-profile-picture-letter-container {
  position:absolute;
  height: 100%;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-modal-profile-picture-letter {
  font-size:40px;
  color:#959595;
  position:absolute;

}

.edit-profile-modal-profile-photo-upload{
  position:absolute;
  z-index:1000;
  height:100%;
  width:100%;
  padding-right:200px;
  margin-left:-200px;
  opacity:0;
  cursor: pointer;

}

.edit-profile-modal-profile-photo-overlay-message-text {
  font-family:Larsseit;
  font-size:12px;

}

.salary-block {
  display: flex;
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 1px solid #838383;
  height:41px;
  background:#FFFFFF;
  width:100%;
  margin-bottom:46px;
  outline:none;
  border-radius:3px;
  padding:0 10px 0 0px;
  max-width: 303px;

}

.inside-money {
  display: flex;
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 0px solid #838383;
  height:41px;
  background:#FFFFFF;
  width:100%;
  outline:none;
  padding:0;
}


.inside-money:focus {
  display: flex;
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 0px solid #838383;
  height:41px;
  background:#FFFFFF;
  width:100%;
  outline:none;
  padding:0;
}

.salary-block input {
  display:block;
}

.salary-block .money-sign {
  display:block;
  height:41px;
  background: #EBEDF2;
  border-right: 1px solid #838383;
  width:30px;
  margin-right: 10px;
  color:#838383;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
.salary-block { position: relative; }
.salary-block input { text-indent: 30px;}
.salary-block .fa-search {
  position: absolute;
  top: 14px;
  left: 7px;
  font-size: 15px;
} */

.create-account-form-message-area {
  border-top: 1px solid #EBEDF2;
  padding-top:17px;
  padding-bottom:17px;
}

.create-account-form-message-area-2 {
  border-top: 1px solid #EBEDF2;
  padding-top:17px;
  padding-bottom:17px;
  margin-top:30px;
}

.create-account-form-buttons-area-2 {
  border-top: 1px solid #EBEDF2;
  padding-top:27px;
  padding-bottom:27px;
}

.create-account-message {
  font-size: 14px;
  color:#226CFF;
}


.input-create {
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 1px solid #838383;
  height:41px;
  background:#FFFFFF;
  display: flex;
  flex-basis: 300px;
  max-width:300px;
  width:100%;
  margin-bottom:16px;
  outline:none;
  border-radius:3px;
  padding:0 10px 0 10px;
  margin-right:auto;
  margin-left: auto;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}

.input-create-half {
  font-family: Larsseit;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  border: 1px solid #838383;
  height:41px;
  background:#FFFFFF;
  display: flex;
  flex-basis: 155px;
  max-width:155px;
  margin-bottom:16px;
  outline:none;
  border-radius:3px;
  padding:0 10px 0 10px;
  margin-right:auto;
  margin-left: auto;
  display: inline;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}
