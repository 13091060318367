.create-account-modal-overlay{
  position:fixed;
  height:100VH;
  background-color: rgba(0,0,0,0);
  z-index: 5000;
  width:100%;
  top:0;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.create-account-modal-container {
  margin-top:5VH;
  margin-left: 10px;
  margin-right:10px;
}

.create-account-modal {
  margin:0 auto 0 auto;
  font-size:18px;
  background: #FFFFFF;
  border-radius: 8px;
  display:flex;
  flex-basis: 600px;
  max-width:600px;
}

.create-account-modal-responsive-container{
  width:100%;


}

.create-account-modal-top-bar {
  height:33px;
  width:100%;
  display:flex;
  justify-content: flex-end;
}

.create-account-modal-x-container {
  padding-right:20px;
  padding-top:15px;
  cursor: pointer;

}

.create-account-modal-x {
  opacity:0.5;

}

.create-account-modal-x:hover {
  opacity:1;

}

.create-account-modal-title-body {

  width:100%;

}

.create-account-modal-title-container {
  display:flex;
  justify-content:space-between;
  margin-left:70px;
  margin-right:70px;
  padding-bottom:10px;
  padding-top:10px;
}

@media (max-width: 600px) {
  .create-account-modal-title {
    width:100%;
    text-align: center;
  }

  .create-account-modal-title-container {
    display:block;
  }
}


.create-account-modal-title-label {
  font-size: 26px;
  font-weight: 500;
  text-align:center;
  width:100%;
}

.create-account-modal-scroll-container {
  overflow-y:auto;
  overflow-x:hidden;

}


.create-account-modal-section {
  width:100%;
}

.create-account-modal-section-container {
  height:100%;
  padding-left:70px;
  padding-right:70px;
  padding-bottom:16px;


}

@media (max-width: 600px) {
  .create-account-modal-section-container {

    padding-left:30px;
    padding-right:30px;
    padding-bottom:16px;
    padding-top:30px;


  }
}
.create-account-modal-section-row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding-top:16px;

}

.create-account-modal-section-column-padding-right {
  width:100%;
  margin-right: 12px;

}

.create-account-modal-section-column{
  width:100%;


}


@media (max-width: 600px) {
  .create-account-modal-section-row{
    display:block;
    align-items: center;
    justify-content: space-between;
    width:100%;


  }

  .create-account-modal-section-column {
    padding-top:16px;


  }

  .create-account-modal-section-row{
    padding-top:0px;

  }

}

.create-account-modal-half-field-container {
  display:flex;
  width:100%;

}

.create-account-modal-field-container {
  display:flex;
  width:100%;

}

.create-account-modal-half-field {
  height:55px;
  width:100%;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #000;
  background-color: #FFF;
  border: solid 1px #ABABAB;
  border-radius:5px;
  font-family: Larsseit;
  outline: none;
  margin-bottom: 0px;

}



.create-account-modal-field {
  height:55px;
  width:436px;
  padding-left:31px;
  padding-right:31px;
  font-size: 16px;
  color: #000;
  background-color: #FFF;
  border: 1px solid #ABABAB;
  border-radius:5px;
  font-family: Larsseit;
  outline: none;
  margin-bottom: 0px;

}

.create-account-accept-message {
  font-size:12px;
  text-align: center;
  color:#838383;
  margin-top:3px;

}

.create-account-modal-error-message {
  font-size:13px;
  text-align: center;
  color:#EF7879;
  margin-top:3px;
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

}

.create-account-modal-login-option {
  text-align: center;
  font-size: 13px;
  color:#838383;
  display:flex;
  justify-content: center;
  margin-top:10px;
  align-items: center;

}

.blue-log-in {
  color:#226CFF;
  opacity:1;
  cursor: pointer;

}

.blue-log-in:hover {
  color:#1D5AD4;
}

.create-account-modal-button-section {
  display:flex;
  border-top: 1px solid #EBEDF2;

}

.create-account-modal-button-section-container {
  width:356px;
  margin: 20px auto 20px auto;
  display:flex;
  justify-content: center;
  font-size: 18px;
}


.create-account-modal-primary-button {
  display:inline-block;
  background: #226CFF;
  border-radius: 12px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
  font-size: 17px;
  cursor:pointer;
  outline:none;
  border:none;
  padding:15px 40px;
  font-weight: 500;
}


.create-account-modal-primary-button-disabled {
  display:inline-block;
  background: #226CFF;
  border-radius: 12px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
  font-size: 17px;
  cursor:pointer;
  outline:none;
  border:none;
  padding:15px 40px;
  font-weight: 500;
  opacity: 0.6;
}

.create-account-modal-primary-button-waiting {
  display:inline-block;
  background: #226CFF;
  border-radius: 12px;
  text-align: center;
  color: rgba(255,255,255,1);
  opacity:1;
  font-size: 17px;
  cursor:pointer;
  outline:none;
  border:none;
  padding:11px 40px;
  font-weight: 500;
  opacity: 0.6;
  min-width: 120px;
}

.create-account-modal-primary-button:hover {
  background-color:#1D5AD4;

}

.create-account-modal-primary-button-label {
}
