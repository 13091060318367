.applicant-card {
  flex-basis:800px;
  margin:15px 0;
  display:flex;
  /* min-height:300px; */


}

.applicant-card-container {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  width:100%;
}

.applicant-card-top-container {
  display:flex;
  border-bottom: 1px solid #EBEDF2;
  align-items: center;
  justify-content: space-between;
}


@media (max-width:600px) {
  .applicant-card-top-container {
    display:block;
    border-bottom: 1px solid #EBEDF2;
    align-items: center;
  }

}

.applicant-card-top-left {
  display:flex;
  align-items: center;
}

.profile-picture-container {
  padding:13px;

}

.profile-picture-border {
  height:100px;
  width:100px;
  background: #FFFFFF;
  border: 1px solid #EFF0F5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  border-radius:50px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  background: #D8D8D8;
  height: 94px;
  width:94px;
  border-radius: 55.5px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.profile-picture img {
  height:100%;
  width:100%;
  object-fit: cover;

}

.profile-picture-letter {
  font-size:40px;
  color:#959595;

}

.applicant-card-top-name {
  font-weight:500;
  font-size: 19px;
  color: #000000;
  letter-spacing: 0;
  display: flex;
  align-items: center;
}

.applicant-card-top-bio {
  font-size: 14px;
  color: #000000;
  margin:3px 0;
  margin-right:20px;

}

.applicant-card-top-information-container {
  width:100%;


}
.applicant-card-top-location {
  font-size: 14px;
  color: #000000;
  margin:3px 0;
}

.applicant-card-interview-container {
  border-bottom: 1px solid #EBEDF2;
  align-items: center;
  width:100%;
  padding:18px 0;
}

.applicant-card-interview-title-container {
  display:flex;
  margin: 0 24px 10px 24px;

}

.applicant-card-stats-row-text {
  font-size:  14px;

}

.applicant-card-interview-title {
  font-weight:500;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;

}

.applicant-card-interview-performance-container {
  display:flex;
  flex-wrap: wrap;
  width:100%;
  margin:0 10px;
}

.applicant-card-stats-container {
  align-items: center;
  padding:13px 0;
  font-size:14px;
}

.applicant-card-stats-row {
  display:flex;
  margin: 8px 24px 8px 24px;
}

.applicant-card-stats-row-icon {
  margin-right: 14px;

}

.applicant-card-action-button-container {
  margin-right:18px;
}

@media (max-width:600px) {
  .applicant-card-action-button-container {

    display: flex;
    margin:5px 18px 15px 18px;
  }

}


.applicant-card-button {
  background: #FFFFFF;
  border: 1px solid #EFF1F4;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  padding:14px 30px;
  font-weight:500;
  font-size: 14px;
  color: #226CFF;
  border-radius:7px;
  display:block;
  text-align: center;
  min-width: 155px;
}

.applicant-card-button:hover {
  background: #FFFFFF;
  opacity: 0.6;
}

.applicant-card-button-second {
  background: #FFFFFF;
  border: 1px solid #EFF1F4;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  padding:14px 30px;
  font-weight:500;
  font-size: 14px;
  color: #226CFF;
  border-radius:7px;
  margin-top:5px;
  display:block;
  text-align: center;
  min-width: 155px;
}

@media (max-width:600px) {
  .applicant-card-button-second {
    margin-top:0;
    margin-left:5px;
  }

}

.applicant-card-button-second:hover {
  background: #FFFFFF;
  opacity: 0.6;
}

.applicant-search-card-container {
  display:flex;
  flex-direction: column

}
