.onboarding-form-header-text-no-margin {
    font-size: 14px;
    color: #838383;
    line-height: 24px;
}


.interview-logos {
  max-height: 220px;
  margin-bottom: 70px;
  display: flex;
  width:90%;
  object-fit: contain;
}

.interview-form-body {
  width:800px;
  /* margin-left: 25px;
  margin-right:25px; */
}

.interview-companies-section-logos-container {
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:34px;
}

@media (max-width:700px){
  .interview-companies-section-logos-container {
    margin-top:0;
  }

  .interview-logos {
    max-height: 80px;
  }

}

.interview-form-header-title {
  font-weight:500;
  font-size: 22px;
  color: #000000;
  margin-bottom:15px;
}

.interview-companies-section-title {
    font-weight: 500;
    font-size: 16px;
    color: #838383;
    letter-spacing: 1.2px;
    text-align: center;
    margin-top: 52px;
    margin-bottom: 52px;
}

.form-label-secondary{

  font-size: 14px;
  margin-bottom: 15px;
  color: #838383;
  /* line-height: 24px; */
  max-width: none;
  margin-top: -15px;
}

.interview-cta {
  color:#000;
  font-weight: 500;
  text-decoration: underline;
}

.react-calendar {
    width: 300px;
    max-width: 100%;
    transform: scale(0.95);
    background: white;
    border: 1px solid #EBEDF2 !important;
      box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
    font-family: Larsseit, Arial, Helvetica, sans-serif;
    line-height: 1.125em;

    border-radius:7px;
}

.react-calendar__tile--active {
    background: #226CFF !important;
    color: white;
    border-radius:3px;
}
.react-calendar__navigation button {
  font-size:14px;
}

.react-calendar__month-view__days__day--weekend {
    color: #000;
}

.react-calendar__month-view__days__day--weekend {
    color: #000 !important;
}


.react-calendar__month-view__days__day--neighboringMonth {
    color: #969696 !important;
}

.react-calendar__tile--active {
  color:white !important;
}

.checkmark-2 {
  margin-left: 5px;
  margin-top:2px;
}

.rc-time-picker-panel-input {
    border: none !important;
}

.react-time-picker__inputGroup__input {
    min-width: 10 !important;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}

.time-selector {
  display: flex;

}

.react-datetime-picker__wrapper {
  border: 1px solid #838383;
  border-radius: 3px;
}

.react-datetime-picker__inputGroup {
    min-width: 250px !important;
    flex-grow: 1;
    display: flex;
    padding: 0 10px 0 10px !important;
    align-items: baseline;
}

.react-datetime-picker__inputGroup__input {
    min-width: auto !important;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input:focus {
    min-width: auto !important;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    border:none;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #226CFF !important;
    color: #fff;
}

.react-datepicker {
    font-family: Larsseit !important;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #838383;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    box-shadow: 0 8px 25px 0 rgba(0,0,0,0.06);
    margin-top:-20px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 0px !important;
}

.react-datepicker__input-container input {
  font-family: Larsseit;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  height: 41px;
  background: #FFFFFF;
  /* margin-bottom: 26px; */
  outline: none;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #EBEDF2 !important;
  box-shadow: 0 8px 25px 0 rgba(0,0,0,0.06);

}

.react-datepicker-popper {
  top:-15px;

}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #226CFF !important;
    color: white;
    font-weight: bold;

}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height:16px !important;

}

.go-forward-button {
  padding-top: 15px;
  color: #838383;
  font-size:14px;
  cursor: pointer;
}

.select-time-container {
  display: flex;
  width:232px;
  justify-content: space-around;

}

.react-add-to-calendar__button {
    padding: 0px !important;
    background-color: #fff !important;
    border: 0px solid #aab9d4 !important;
    border-radius: 3px;
    color: #226CFF !important;
    font-size:12px;
    margin-top:-100px;
}

.react-add-to-calendar__button:hover {
    /* color:#1D5AD4 !important; */
    text-decoration: underline !important;
}

.react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    position: relative;
    margin: 0 0 !important;
}

.react-add-to-calendar__dropdown {
  margin-left:-6px;

}

.react-add-to-calendar__dropdown ul {
  /* margin-left:-35px !important; */
  font-size:12px;
  padding:5px 0 !important;

}

.react-add-to-calendar__dropdown {
    position: absolute;
    top: 30px;
    left: -4px;
    width: 100%;
    padding: 5px 0 5px 8px;
    border: 1px solid #EBEDF2 !important;
    box-shadow: 0 8px 25px 0 rgba(0,0,0,0.05);
    background-color: #fff;
    text-align: left;
    border-radius: 3px;
}

.react-add-to-calendar__dropdown li:hover {
  /* margin-left:-35px !important; */
  text-decoration: underline !important;

}

.edit-schedule {
  color:#226CFF;
  font-size: 12px;
  cursor: pointer;
  margin-top:4px;
  margin-right:20px;
}

.edit-schedule:hover {
  text-decoration: underline;

}

.scoot-left {
  left:-80px;

}

.react-datepicker__triangle {
  border-bottom-color: #FAFBFE !important;

}

.big-checkmark {
  margin-left:3px;
  margin-bottom:-2px;
}
