.applicant-profile-cards {

}

.applicant-profile-cards-container {
  max-width:800px;
  margin-left: auto;
  margin-right:auto;
  display: flex;
  height:100%;
}

.employer-applicant-profile {
  position:fixed;
  width:100%;
  height:100%;
  overflow-y: auto;
  top:0;
  left: 0;
  z-index:1000;

}
