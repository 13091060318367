.filters{
  flex-basis:300px;
  display:flex;
  flex-shrink: 1;

}

.filters-container {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  width:100%;
  margin:15px 24px 15px 0;
}

@media (max-width:700px) {
  .filters-container {
    margin:15px 0 15px 0;

  }

}

.filters-section-label {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  padding:16px 24px;
  border-bottom: 1px solid #EBEDF2;
}
