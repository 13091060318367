.applicant-interview-card {
  flex-basis:800px;
  margin:15px 0;
  display:flex;
  /* min-height:300px; */
}

.applicant-interview-card-container {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 9px 25px 0 rgba(0,0,0,0.05);
  border-radius: 7px;
  width:100%;
  padding-top:18px;
  white-space: pre-line;
}

.applicant-interview-card-title-container {
  border-bottom: 1px solid #EBEDF2;
  padding-bottom: 13px;
}

.applicant-card-interview-performance-container {
  margin-bottom:11px;
}

.applicant-interview-card-title {
  font-weight:500;
  font-size: 19px;
  color: #000000;
  letter-spacing: 0;
  margin: 6px 24px 10px 24px;
}

.applicant-interview-card-question-container {
  padding: 24px 0;
  border-bottom: 1px solid #EBEDF2;
}

.applicant-interview-section-title {
  color: #226CFF;
  font-weight:500;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0 24px;
  margin-bottom: 5px;
}

.applicant-interview-card-question{
  font-weight:500;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  margin: 0 24px;
}


.applicant-interview-card-answer {
  font-size:14px;
  margin: 24px 24px 0 24px;

}
.applicant-interview-card-supporting-link-container {
  display:flex;
  margin: 24px 24px 0 24px;
}
.applicant-interview-card-supporting-link {
  background: #FFFFFF;
  border: 1px solid #EBEDF2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
  background: #FFF;
  border-radius:7px;
  padding:16px 46px 16px 0;
  font-size:14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display:flex;
}

.applicant-interview-card-supporting-link-title {
  font-weight:500;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;

}

.applicant-interview-card-supporting-link-url {
  font-size: 12px;
  color: #838383;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:300px;
}

@media (max-width:800px) {
  .applicant-interview-card-supporting-link-url {
    max-width:200px;
  }

}

.blue-link-icon {
  margin:0 17px;

}

.applicant-profile-back {
  font-size:14px;
  color:#838383;
  text-align: center;
  padding:26px 0 10px 0;
  cursor: pointer;
}

.applicant-profile-back:hover {
  text-decoration: underline;
}
